.InspireForm_ {
  display: flex;
  justify-content: center;
  margin-top: 2rem; }
  .InspireForm_ div {
    display: flex;
    justify-content: center; }
    @media (max-width: 700px) {
      .InspireForm_ div {
        justify-content: flex-start; } }
  .InspireForm_intro-text {
    text-align: center;
    margin-bottom: 2rem;
    width: 100%;
    max-width: 70rem; }
  .InspireForm_left {
    text-align: left;
    display: block; }

.HomeScreen_ {
  box-sizing: border-box; }
  .HomeScreen_cardcontainer {
    display: flex;
    margin-bottom: 10rem;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap; }

.steps-container {
  display: flex;
  flex-direction: row;
  padding-top: 2rem; }
  .steps-container h3 {
    margin-bottom: 1rem; }
  .steps-container ul,
  .steps-container li {
    display: inline-block;
    list-style-type: none; }
  .steps-container li {
    margin-bottom: 2rem; }
  @media (max-width: 700px) {
    .steps-container {
      flex-direction: column; } }
  .steps-container div {
    width: 50%; }
    .steps-container div.Button_container, .steps-container div.Button_link {
      width: auto; }
    .steps-container div:nth-child(2) {
      padding-left: 2rem; }
    @media (max-width: 700px) {
      .steps-container div {
        width: 100%; } }
  .steps-container .quotecontainerfkcss {
    width: 100%;
    box-sizing: border-box;
    font-size: 2rem;
    text-align: center;
    position: relative;
    margin-top: 3rem;
    margin-bottom: 4rem; }
    .steps-container .quotecontainerfkcss .quote {
      width: 80%;
      display: inline-block;
      margin: 0; }
    .steps-container .quotecontainerfkcss .open,
    .steps-container .quotecontainerfkcss .close {
      font-size: 7.2rem;
      color: #FFB638; }
    .steps-container .quotecontainerfkcss .open {
      position: absolute;
      top: -2rem;
      left: 2rem; }
    .steps-container .quotecontainerfkcss .close {
      position: absolute;
      bottom: -6rem;
      right: 2rem; }
  .steps-container img {
    max-width: 80%;
    margin: 2rem auto;
    display: block; }
  .steps-container .after-text {
    text-align: center;
    font-size: 1.4rem;
    width: 60%;
    display: block;
    margin: 0 auto; }

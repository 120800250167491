.SurveyScreen_container {
  display: flex;
  flex-direction: column; }
  .SurveyScreen_container h3 {
    margin-bottom: 2rem; }
  .SurveyScreen_container p {
    text-align: center; }

.SurveyScreen_midtitle {
  margin-top: 2rem; }

.SurveyScreen_link {
  display: block;
  margin-bottom: 0;
  text-align: center;
  text-decoration: none;
  background-color: #FFB638;
  color: #fff;
  padding: 1rem 2rem;
  border-radius: 5px;
  transition: 0.2s ease-out; }
  .SurveyScreen_link:hover {
    color: #fff;
    background-color: #ffa305; }

.ChallengeScreen_ {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .ChallengeScreen_flexcol {
    flex-direction: column; }
  .ChallengeScreen_vcenter {
    font-size: 1.8rem;
    color: #827f86; }
    .ChallengeScreen_vcenter svg {
      vertical-align: text-bottom;
      height: 1.8rem; }
  .ChallengeScreen_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-image: url("../../../images/challenge_header.svg");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat; }
    @media (max-width: 800px) {
      .ChallengeScreen_header {
        background-image: none; } }
    .ChallengeScreen_header_logo {
      position: absolute;
      top: 2rem;
      width: 9rem;
      left: calc(50% - 4.5rem);
      right: calc(50% - 4.5rem); }
      @media (max-width: 1000px) {
        .ChallengeScreen_header_logo {
          width: 7rem;
          left: calc(50% - 3.5rem);
          right: calc(50% - 3.5rem); } }
      @media (max-width: 800px) {
        .ChallengeScreen_header_logo {
          width: 5rem;
          left: calc(50% - 2.5rem);
          right: calc(50% - 2.5rem); } }
    .ChallengeScreen_header_title {
      margin-top: 15rem;
      font-size: 5.5rem;
      color: #333;
      line-height: 120%;
      font-weight: 800;
      text-align: center; }
      @media (max-width: 1200px) {
        .ChallengeScreen_header_title {
          margin-top: 17rem; } }
      @media (max-width: 1100px) {
        .ChallengeScreen_header_title {
          font-size: 5rem; } }
      @media (max-width: 950px) {
        .ChallengeScreen_header_title {
          font-size: 4rem; } }
      @media (max-width: 800px) {
        .ChallengeScreen_header_title {
          margin-top: 8rem;
          font-size: 3rem; } }
    .ChallengeScreen_header .Input_ta-container {
      box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.5);
      border: 2px solid #ffb638;
      border-radius: 2rem;
      width: 50rem;
      resize: none; }
      .ChallengeScreen_header .Input_ta-container::placeholder {
        vertical-align: center; }
      @media (max-width: 550px) {
        .ChallengeScreen_header .Input_ta-container {
          width: 100%; } }
  .ChallengeScreen_ .inactive_title {
    padding: 0 16rem; }
    @media (max-width: 800px) {
      .ChallengeScreen_ .inactive_title {
        padding: 0; } }
  .ChallengeScreen_ .ContentHeader_container {
    width: 100%;
    margin-top: 8rem; }
    @media (max-width: 680px) {
      .ChallengeScreen_ .ContentHeader_container {
        margin-top: 4rem; } }
  .ChallengeScreen_img-confidas {
    max-width: 100%;
    margin-bottom: 2rem; }
  .ChallengeScreen_two-cols {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 2rem; }
    .ChallengeScreen_two-cols > div {
      width: 100%; }
    @media (max-width: 680px) {
      .ChallengeScreen_two-cols {
        flex-direction: column; } }
  .ChallengeScreen_col-left {
    margin-top: 10rem;
    margin-right: 8rem; }
    .ChallengeScreen_col-left_img {
      margin-top: 5rem; }
  .ChallengeScreen_col-right {
    margin-left: 8rem; }
  .ChallengeScreen_col-left img, .ChallengeScreen_col-right img {
    max-width: 100%; }
  .ChallengeScreen_col-left_img-clipped, .ChallengeScreen_col-right_img-clipped {
    border-radius: 100px; }
  @media (max-width: 680px) {
    .ChallengeScreen_col-left, .ChallengeScreen_col-right {
      flex-direction: column;
      margin: 0; } }
  .ChallengeScreen_idea-link a {
    color: #FFB638; }
  .ChallengeScreen_ .ChallengeScreen_replies {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 4rem;
    width: 100%; }
  .ChallengeScreen_p-big {
    font-weight: 700; }
    .ChallengeScreen_p-big:before {
      content: open-quote; }
    .ChallengeScreen_p-big:after {
      content: close-quote; }

.Spinner_ {
  display: block;
  border: 6px solid #f3f3f3;
  /* Light grey */
  border-top: 6px solid #FFB638;
  /* Blue */
  border-radius: 50%;
  min-width: 4rem;
  max-width: 4rem;
  min-height: 4rem;
  max-height: 4rem;
  animation: spin 1s cubic-bezier(0.51, 0.07, 0.43, 0.78) infinite; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

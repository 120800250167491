.ChallengeExtended_container {
  width: 100%;
  max-width: 80rem;
  padding: 2rem 4rem;
  border-radius: 1rem;
  background-color: #fff;
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.5);
  position: relative; }
  .ChallengeExtended_container h3 {
    font-weight: 600;
    font-size: 1.8rem;
    margin-top: 2rem;
    margin-bottom: 1rem; }

.ChallengeExtended_close {
  position: absolute;
  top: -1.5rem;
  right: -1.5rem;
  text-align: center;
  font-size: 3.5rem;
  height: 4rem;
  width: 4rem;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.25); }
  .ChallengeExtended_close:hover {
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25); }

.ChallengeExtended_attachments p,
.ChallengeExtended_attachments a {
  margin-bottom: 0; }

.ChallengeExtended_photo {
  max-width: 30rem;
  max-height: 20rem; }

.ChallengeExtended_reply_input {
  display: flex;
  flex-direction: row; }
  @media (max-width: 700px) {
    .ChallengeExtended_reply_input {
      flex-direction: column; }
      .ChallengeExtended_reply_input .Input_container {
        width: 100%; } }
  .ChallengeExtended_reply_input > * {
    margin-right: 1rem; }

.ChallengeExtended_replies_by_company {
  margin-bottom: 1rem; }
  .ChallengeExtended_replies_by_company_description {
    background-color: #ffb638;
    margin: 0;
    display: inline-block;
    position: relative;
    border-radius: 5px;
    color: #fff;
    padding: 0 1rem; }
  .ChallengeExtended_replies_by_company_name {
    padding-left: 1rem; }

.ChallengeExtended_replies_item, .ChallengeExtended_replies_new {
  margin-bottom: 1rem; }
  .ChallengeExtended_replies_item_description, .ChallengeExtended_replies_new_description {
    margin: 0;
    display: inline-block;
    position: relative;
    border-radius: 5px;
    background-color: #f7f7f7;
    padding: 0 1rem; }
    .ChallengeExtended_replies_item_description_date, .ChallengeExtended_replies_new_description_date {
      position: absolute;
      width: 12rem;
      margin-left: 2rem;
      padding: 0 1rem;
      background-color: #444;
      color: #fff;
      border-radius: 1rem;
      text-align: center;
      opacity: 0;
      transition: 0.25s ease-out; }
    .ChallengeExtended_replies_item_description:hover .ChallengeExtended_replies_item_description_date, .ChallengeExtended_replies_new_description:hover .ChallengeExtended_replies_item_description_date, .ChallengeExtended_replies_item_description:hover .ChallengeExtended_replies_new_description_date, .ChallengeExtended_replies_new_description:hover .ChallengeExtended_replies_new_description_date {
      opacity: 1; }

.ChallengeExtended_replies_new .ChallengeExtended_replies_item_description {
  animation: new 1.5s ease-out 1; }

@keyframes new {
  0% {
    background-color: #f2994a; }
  100% {
    background-color: #f7f7f7; } }

.PrivacyScreen_back {
  margin-top: 2rem;
  margin-bottom: 0; }
  .PrivacyScreen_back a {
    margin-bottom: 0; }

.PrivacyScreen_container {
  width: 80rem;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 4rem; }
  @media (max-width: 800px) {
    .PrivacyScreen_container {
      width: 100%; } }
  @media (max-width: 450px) {
    .PrivacyScreen_container {
      word-wrap: break-word;
      padding: 0 2rem; } }
  .PrivacyScreen_container h1 {
    margin-top: 3rem;
    margin-bottom: 2rem; }
  .PrivacyScreen_container h2 {
    margin-top: 4rem;
    margin-bottom: 2rem; }
  .PrivacyScreen_container h3 {
    margin-top: 2rem;
    margin-bottom: 1rem; }
  .PrivacyScreen_container ul {
    margin-left: 4rem; }
  .PrivacyScreen_container li {
    line-height: 150%; }
  .PrivacyScreen_container a {
    color: #FFB638;
    transition: 0.2s ease-out; }
    .PrivacyScreen_container a:hover {
      color: #d18400; }

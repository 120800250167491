.Input_centeredlabel {
  text-align: center; }

.Input_container {
  width: 100%;
  max-width: 70rem;
  padding: 0 2rem;
  margin-bottom: 2rem;
  height: 4rem;
  border: 1px solid #d0d0d0;
  border-radius: 0.5rem;
  font-family: Ubuntu;
  font-size: 1.4rem;
  color: #222; }
  .Input_container::placeholder {
    color: #b3b3b3; }
  .Input_container:focus {
    outline: none; }
  .Input_container:required {
    outline: none;
    box-shadow: none; }

.Input_ta-container {
  width: 100%;
  max-width: 70rem;
  padding: 1rem 2rem;
  margin-bottom: 2rem;
  border: 1px solid #d0d0d0;
  border-radius: 0.5rem;
  font-family: Ubuntu;
  font-size: 1.4rem;
  color: #222;
  line-height: 160%; }
  .Input_ta-container::placeholder {
    color: #b3b3b3; }
  .Input_ta-container:focus {
    outline: none; }
  .Input_ta-container:required {
    outline: none;
    box-shadow: none; }

.Input_label {
  display: block;
  margin-bottom: 0.5rem;
  width: 70rem; }
  @media (max-width: 700px) {
    .Input_label {
      width: 100%; } }

.Input_required-star {
  color: #FFB638; }

.Input_invalid {
  border-color: #f44336; }

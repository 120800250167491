.Footer_ {
  margin-top: 4rem;
  width: 100%;
  background-color: #575757;
  color: #fff;
  padding: 2rem 2rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center; }
  .Footer_ a {
    color: #fff; }
    .Footer_ a:hover {
      color: #FFB638; }
  .Footer_ .footerImg {
    width: 200px;
    margin: 0 0;
    padding: auto 20px; }
  .Footer_ .container {
    margin: auto 0;
    padding: 0 20px;
    width: auto;
    border-left-width: .4pt;
    border-left-color: white;
    border-left-style: solid; }

.Header_container {
  background-color: #fff;
  width: 100%;
  height: 60rem;
  margin-bottom: 5rem;
  padding: 0 4rem; }
  @media (max-width: 1400px) {
    .Header_container {
      height: 40rem; } }
  @media (max-width: 1000px) {
    .Header_container {
      height: 30rem; } }
  @media (max-width: 1050px) {
    .Header_container {
      padding: 0; } }
  @media (max-width: 800px) {
    .Header_container {
      height: 25rem; } }
  @media (max-width: 640px) {
    .Header_container {
      height: auto; } }
  @media (max-width: 640px) {
    .Header_container .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center; } }

.Header_logo {
  margin-top: 2rem;
  width: 10rem; }
  @media (max-width: 1400px) {
    .Header_logo {
      width: 8rem; } }
  @media (max-width: 1000px) {
    .Header_logo {
      margin-top: 1rem;
      width: 5rem; } }
  @media (max-width: 640px) {
    .Header_logo {
      width: 8rem;
      margin-bottom: 1rem; } }

.Header_image {
  position: absolute;
  top: 0;
  right: 0;
  height: 50rem; }
  @media (max-width: 1400px) {
    .Header_image {
      height: 40rem;
      right: 10rem; } }
  @media (max-width: 1180px) {
    .Header_image {
      right: 4rem; } }
  @media (max-width: 1050px) {
    .Header_image {
      right: 0; } }
  @media (max-width: 1000px) {
    .Header_image {
      height: 30rem;
      right: 10rem; } }
  @media (max-width: 910px) {
    .Header_image {
      right: 0; } }
  @media (max-width: 800px) {
    .Header_image {
      height: 25rem; } }
  @media (max-width: 640px) {
    .Header_image {
      display: none; } }

.Header_title-huge {
  position: absolute;
  top: 20rem;
  font-size: 5.5rem;
  color: #333;
  line-height: 120%;
  font-weight: 700; }
  @media (max-width: 1500px) {
    .Header_title-huge {
      font-size: 5.3rem; } }
  @media (max-width: 1400px) {
    .Header_title-huge {
      font-size: 4.5rem;
      top: 15rem; } }
  @media (max-width: 1250px) {
    .Header_title-huge {
      font-size: 4.3rem; } }
  @media (max-width: 1000px) {
    .Header_title-huge {
      font-size: 3.2rem;
      top: 11rem; } }
  @media (max-width: 800px) {
    .Header_title-huge {
      font-size: 2.8rem;
      top: 9rem; } }
  @media (max-width: 640px) {
    .Header_title-huge {
      position: initial; } }

.Header_title-big {
  position: absolute;
  top: 38rem;
  font-size: 2.1rem;
  color: #bdbdbd;
  font-weight: 600; }
  @media (max-width: 1500px) {
    .Header_title-big {
      font-size: 1.9rem; } }
  @media (max-width: 1400px) {
    .Header_title-big {
      top: 27rem; } }
  @media (max-width: 1000px) {
    .Header_title-big {
      font-size: 1.7rem;
      top: 20rem; } }
  @media (max-width: 800px) {
    .Header_title-big {
      font-size: 1.4rem;
      top: 17rem; } }
  @media (max-width: 680px) {
    .Header_title-big {
      font-size: 1.3rem; } }
  @media (max-width: 640px) {
    .Header_title-big {
      position: initial;
      margin-bottom: 3rem;
      font-size: 1.4rem; } }

.AnswerScreen_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8rem;
  max-width: 100%; }
  @media (max-width: 800px) {
    .AnswerScreen_container {
      flex-direction: column; } }
  @media (max-width: 1400px) {
    .AnswerScreen_container {
      padding: 2rem; } }
  .AnswerScreen_container .ChallengeTitle_ {
    text-align: center; }
  .AnswerScreen_container > * {
    max-width: 100%; }

.AnswerScreen_content-header {
  display: inline-block;
  margin: 0 auto; }

.AnswerScreen_upload-photo {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem; }
  .AnswerScreen_upload-photo_photo {
    width: 20rem;
    height: 20rem;
    object-fit: cover;
    margin-bottom: 1rem; }

.AnswerScreen_attachments {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem; }
  .AnswerScreen_attachments_items {
    font-size: 1.4rem;
    margin-bottom: 1rem; }
    .AnswerScreen_attachments_items div {
      margin-bottom: 0.5rem; }
    .AnswerScreen_attachments_items_delete {
      padding: 1rem; }

.AnswerScreen_spinner {
  margin-bottom: 2rem; }

.AnswerScreen_error-message {
  color: #f44336; }

.AnswerScreen_errors {
  margin-bottom: 2rem;
  margin-top: 1rem;
  padding: 1rem 2rem; }
  .AnswerScreen_errors h2 {
    margin-bottom: 1rem;
    font-size: 1.8rem; }
  .AnswerScreen_errors li {
    margin-bottom: 1rem;
    margin-left: 2rem;
    list-style-type: circle; }

.AnswerScreen_checkbox {
  width: 40rem;
  margin-bottom: 1rem;
  line-height: 150%; }

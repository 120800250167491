.ContentHeader_container {
  flex: 1;
  display: flex;
  justify-content: center;
  line-height: 150%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-weight: 400;
  font-size: 2.4rem;
  color: #4A4A4A;
  letter-spacing: 0.5rem;
  text-transform: uppercase;
  border-bottom: 1px solid #d6d6d6; }
  @media (max-width: 600px) {
    .ContentHeader_container {
      font-size: 2rem;
      padding-bottom: 0.5rem; } }

.NotFoundScreen_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff; }
  .NotFoundScreen_container p {
    margin-bottom: 20rem;
    font-size: 2.2rem;
    line-height: 180%;
    color: #4A4A4A;
    text-align: center; }

.Card_container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 2%;
  padding: 3rem 2rem 1rem;
  width: 33%;
  text-align: center;
  align-items: center;
  box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 2.5rem; }
  @media (max-width: 1050px) {
    .Card_container {
      width: 48%; } }
  @media (max-width: 600px) {
    .Card_container {
      width: 98%; } }
  .Card_container strong {
    font-weight: 700; }

.Card_head {
  font-size: 2.2rem;
  margin-bottom: 2rem;
  color: #737373;
  line-height: 150%;
  height: 7rem; }
  @media (max-width: 720px) {
    .Card_head {
      height: 9rem; } }
  .Card_head .icon {
    color: #bfa9a2; }

.Card_title {
  height: 12rem;
  display: flex;
  align-items: center; }
  .Card_title a {
    font-size: 2.5rem;
    color: #FFB638;
    margin-bottom: 2rem;
    transition: 0.2s ease-out;
    text-decoration: none; }
    .Card_title a:hover {
      color: #eb9400; }

.Card_image {
  height: 20rem;
  width: 20rem;
  align-self: center;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 2rem; }

.Card_reply-count {
  color: #737373; }

.Widget_ {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding: 1rem 2rem;
  text-align: center; }
  .Widget_head {
    margin: 1rem 0; }
  .Widget_description {
    margin: 2rem 0; }
  .Widget_ h3 {
    margin-bottom: 2rem; }
    .Widget_ h3 a {
      font-weight: 500;
      font-size: 2.4rem;
      color: #FFB638;
      line-height: 150%;
      text-decoration: none; }
      .Widget_ h3 a:hover {
        text-decoration: underline; }
  .Widget_ img {
    width: 20rem;
    height: 20rem;
    border-radius: 50%;
    object-fit: cover; }
  .Widget_ .Button_container {
    margin-bottom: 2rem; }

.CompanyDashboardScreen_ .container {
  margin-top: 2rem; }

.CompanyDashboardScreen_header {
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  height: 6rem;
  line-height: 6rem;
  box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.15); }
  .CompanyDashboardScreen_header > * {
    margin-right: 2rem; }
  .CompanyDashboardScreen_header_logo {
    margin-right: auto;
    margin-left: 2rem; }
    .CompanyDashboardScreen_header_logo img {
      height: 5rem; }

.CompanyDashboardScreen_widget-title {
  font-size: 1.6rem;
  font-family: 'Ubuntu', sans-serif;
  color: #FFB638;
  transition: 0.25s ease-out;
  line-height: 200%;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  background-color: #fafbfc; }
  .CompanyDashboardScreen_widget-title:hover {
    color: #eb9400; }

.CompanyDashboardScreen_widget .Input_ta-container {
  width: 40rem;
  height: 15rem; }

.CompanyDashboardScreen_widget div {
  display: flex;
  flex-direction: row;
  align-items: center; }

.CompanyDashboardScreen_widget p {
  margin-bottom: 1rem; }

.CompanyDashboardScreen_widget #copied-to-clipboard {
  display: none;
  animation: show-copied-to-clipboard 0.3s ease-out 1;
  position: relative;
  padding: 0 2rem; }

@keyframes show-copied-to-clipboard {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.CompanyDashboardScreen_replies {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.CompanyDashboardScreen_ h2 {
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-size: 2.4rem; }

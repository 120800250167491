* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box; }

html {
  font-size: 62.5%; }

body {
  font-family: Ubuntu, sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  color: #4A4A4A;
  background-color: #fafbfc; }

p {
  margin-bottom: 2rem;
  line-height: 175%; }

pre {
  font-family: Ubuntu, sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 175%;
  white-space: pre-wrap;
  margin-bottom: 2rem;
  color: #4A4A4A; }

strong {
  font-weight: 600; }

a {
  color: #827f86;
  margin-bottom: 2rem;
  text-decoration: underline;
  cursor: pointer;
  transition: 0.1s ease-out; }
  a:hover {
    color: #5c5a5f; }
  a:active {
    color: #5c5a5f; }

.container {
  width: 105rem;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 4rem; }
  @media (max-width: 1050px) {
    .container {
      width: 100%; } }
  @media (max-width: 450px) {
    .container {
      padding: 0 2rem; } }
  .container.accent {
    background: #575757;
    color: white;
    width: auto;
    padding: 4rem 4rem; }
    @media (max-width: 450px) {
      .container.accent {
        padding: 4rem 2rem; } }
    .container.accent .ContentHeader_container {
      color: white;
      width: 97rem;
      margin: 0 auto;
      max-width: 100%;
      padding: 0 4rem; }
      @media (max-width: 1050px) {
        .container.accent .ContentHeader_container {
          width: 100%; } }
      @media (max-width: 450px) {
        .container.accent .ContentHeader_container {
          padding: 0 2rem; } }

.centered-container {
  display: flex;
  width: 100%;
  min-height: 90vh;
  justify-content: center;
  align-items: center; }

.error {
  color: #f44336; }

@font-face {
  font-family: Ubuntu;
  src: url("./fonts/Ubuntu/Ubuntu-Light.ttf");
  font-weight: 400; }

@font-face {
  font-family: Ubuntu;
  src: url("./fonts/Ubuntu/Ubuntu-Regular.ttf");
  font-weight: 500; }

@font-face {
  font-family: Ubuntu;
  src: url("./fonts/Ubuntu/Ubuntu-Medium.ttf");
  font-weight: 600; }

@font-face {
  font-family: Ubuntu;
  src: url("./fonts/Ubuntu/Ubuntu-Bold.ttf");
  font-weight: 700; }

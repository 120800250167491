.ChallengeReply_ {
  width: calc(33% - 2rem);
  padding: 2rem;
  margin: 1rem;
  background-color: #fff;
  border: 1px solid #d0d0d0;
  border-radius: 2.5rem;
  box-shadow: 1px 2px 7px 0 rgba(0, 0, 0, 0.25);
  color: #595959; }
  .ChallengeReply_final {
    width: calc(50% - 2rem); }
  @media (max-width: 1100px) {
    .ChallengeReply_ {
      width: calc(50% - 3rem); } }
  @media (max-width: 664px) {
    .ChallengeReply_ {
      width: calc(100% - 2rem); } }
  .ChallengeReply_description_short {
    font-size: 2.2rem;
    margin-bottom: 1rem; }
    .ChallengeReply_description_short a {
      text-decoration: none;
      color: #737373; }
      .ChallengeReply_description_short a:hover {
        text-decoration: underline; }
  .ChallengeReply_stats {
    display: flex;
    justify-content: center;
    color: #737373;
    margin: 2rem 2rem 1rem; }
  .ChallengeReply_button-container {
    text-align: center; }
    .ChallengeReply_button-container p {
      margin-bottom: 1rem; }
  .ChallengeReply_overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 5rem 10rem;
    overflow-y: auto;
    max-height: 100%;
    transition: 2s ease-out;
    animation: turnwhite 0.25s ease-out 1; }

@keyframes turnwhite {
  from {
    background-color: rgba(0, 0, 0, 0); }
  to {
    background-color: rgba(0, 0, 0, 0.2); } }
    @media (max-width: 800px) {
      .ChallengeReply_overlay {
        padding: 2rem; } }
    .ChallengeReply_overlay .ChallengeExtended_container {
      opacity: 1;
      animation: show-extended 0.25s ease-out 1;
      position: relative; }

@keyframes show-extended {
  0% {
    opacity: 0;
    top: -1rem; }
  100% {
    opacity: 1;
    top: 0; } }
  .ChallengeReply_reply {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .ChallengeReply_reply a {
      margin-left: 2rem; }
  .ChallengeReply_photo {
    width: 20rem;
    height: 20rem;
    object-fit: cover; }
